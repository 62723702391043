#answer{
	/* fallback for old browsers */
  background: var(--Cuboh-color-purple1);
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
  width: 100%;
  height: 100%;
}

section{
    padding: 20px 10px;
}

.navbar{
    background: -webkit-linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    background: linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    box-shadow: var(--shadow);
    display: block;
    text-align: center;
}

.barProgress{
    width: 3%;
}

section .section-title{
	text-align:center;
	color:var(--Cuboh-color-purple1);
	margin-bottom:50px;
	text-transform:uppercase;
}

#answer .card{
	padding: 1rem!important;
	margin-bottom:1rem;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}

#headerAnswer .text-progress{
    vertical-align: middle;
    color: white;
    font-weight: bold;
}

.navbar{
    display: inline-flex;
}

.progress-question{
    font-size:25px;
    vertical-align: middle;
    font-weight: bold;
}

.box-sort p{
    position: initial;
    font-size: 20px;
    padding: 0;
    margin:0;
}

.sort-first{
    content: "1º";
}
.sort-second{
    /*content: "\f0eb";*/
    content:"2º"
}
.sort-third{
    content: "3º";
}
.sort-fourth{
    content: "4º";
}