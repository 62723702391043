#Home .btnLink {
    margin: 0px;
    width: 100%;
}

#Home .card-columns .card {
    transition: all .6s ease-in-out;
}

#Home .card-columns .card .card-body .card-title {
    color: var(--Cuboh-color-purple1);
    font-weight: 600;
}

#Home .card-columns .card:hover {
    border: 5px solid var(--Cuboh-color-blue3);
    transform: scale(0.9);
}