.content .pdfViewer .page {
    border: 0;
}

.content .mobile__pdf__container {
    overflow: hidden;
    box-sizing: content-box;
    box-shadow: unset;
}

.content .mobile__pdf__container #viewerContainer {
    position: relative;
    scroll-behavior: unset;
    top: 0rem;
    cursor: -webkit-grab;
}