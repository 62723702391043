#Contact img.avatar-cuboh {
    width: 70%;
    height: 70%;
    text-align: center;
    align-self: center;
}

@media(max-width: 575px) {
    #Contact img.avatar-cuboh {
        width: 50%;
        height: 50%;
    }
}