.carousel-inner {
    border-radius: 0 10px 10px 0;
}

.carousel.slide {
    width: 100%;
    height: 80vh;
}

.img-fluid {
    width: 100%;
    height: 80vh;
}

.banner-text {
    width: 100%;
    text-align: center;
}

.banner-text {
    text-shadow: 0.15em 0.15em 0.15em #333
}

.banner-text p {
    font-size: 1.15em;
}
