@media(max-width: 575px) {
    table thead tr th.HiddenColumnOnMobile {
        display: none;
    }
    table tbody tr td.HiddenColumnOnMobile {
        display: none;
    }
    table tbody tr td.ColumnTextShort {
        max-width: 15ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

table tbody tr td {
    vertical-align: middle!important;
}

table tbody tr td.ColumnTextShort {
    max-width: 18ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table tfoot tr th select.inputListFooter {
    width: 8ch;
    height: 3.5ch;
    color: var(--Cuboh-color-purple1);
    font-weight: 700;
}

table tbody tr td div.btn-login {
    cursor: pointer;
}

div#TestList div.card-title-info {
    background: #eee;
    padding: 10px;
    color: var(--Cuboh-color-purple1);
    border-bottom: 1px solid var(--Cuboh-color-blue3);
}

div#TestList div.card-title-info h5 {
    font-weight: 600;
}

div#TestList div.card-title-info span {
    font-size: 0.9rem;
}

div#TestList div.card-title-body {
    background: #eee;
    padding: 10px;
    color: #000;
}

div#TestList div.card-title-body div.border-cubohColor {
    border-right: 0.2px solid var(--Cuboh-color-blue3);
}

div#TestList div.card-title-body h6 {
    margin-bottom: 4px;
    font-size: 14px;
}

div#TestList div.card-title-body h6.note {
    font-size: 12px;
}

div#TestList div.card-title-body h6 b {
    color: var(--Cuboh-color-purple1);
    font-size: 15px;
    font-weight: 500;
}

div#TestList div.card-title-body button.buttonCuboh {
    color: var(--Cuboh-color-purple1);
    border-color: var(--Cuboh-color-purple1);
    font-weight: 550;
}

div#TestList div.card-title-body button.buttonCuboh:disabled {
    background: var(--secondary);
    color: #eee;
}

div#TestList div.card-title-body button.buttonCuboh:enabled:hover {
    color: #fff;
    background: var(--Cuboh-color-purple1);
}

div#TestList div.card-title-body button.buttonCubohCancel:enabled:hover {
    color: #fff;
    background: var(--danger);
}

div#TestList div.card-title-body button.buttonCubohEdit:enabled:hover {
    color: #fff;
    background: var(--success);
}

div#TestList div.row div.col-9 {
    align-self: center;
}

div#TestList div.custom-switch {
    cursor: pointer;
    font-size: 1.2em;
}

div#TestList div.custom-switch label {
    cursor: pointer;
    font-size: 0.8em;
    text-align: center;
}

div#TestList div.card-title-info img.profile-pic {
    width: 90px;
    background: #fff;
    border-radius: 50%;
    border: 2px solid var(--Cuboh-color-blue3);
}