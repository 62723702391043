header.header {
    /*
    background: -webkit-linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    background: linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    */
    background: var(--Cuboh-color-purple1);
    padding: 0px 05px;
    overflow: hidden;
    white-space: nowrap;
    box-shadow: var(--shadow);
    vertical-align: 20%;
}

header .itens {
    display: list-item;
}

header .item {
    width: 90px;
}

header .item i {
    font-size: 1.5em;
    text-align: right;
}

header a {
    display: block;
    text-align: center;
    padding: 16px 0px;
    margin: 0px;
    transition: all 0.3s ease;
    color: #eee;
    transform: scale(1);
}

header a:hover, header a:focus {
    background: #eee;
    color: var(--Cuboh-color-blue3);
    transform: scale(1.2);
}

header .badge-counter {
    position: absolute;
    transform: scale(.6);
    transform-origin: top right;
    right: 1.2rem;
    margin-top: -.50rem;
}