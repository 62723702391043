.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: var(--aside-width);
    max-width: var(--aside-width);
    min-height: 100%;
}

a[data-toggle="collapse"], span[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
}

p {
    font-size: 1.1em;
    font-weight: 100;
    line-height: 1em;
    color: #000;
}

ul p {
    border-bottom: 1px solid var(--Cuboh-color-blue1);
    padding: 0px 2px;
    vertical-align: middle;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

a, a:hover, a:focus, span, span:hover, span:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar {
    background: var(--Cuboh-color-purple1);
    color: #eee;
    transition: all 0.3s;
    vertical-align: middle;
}

#sidebar .sidebar-header {
    padding: 10px 5px;
    background: var(--Cuboh-color-purple1);
    border-top: 1px solid var(--Cuboh-color--blue1);
    justify-content: center;
    text-align: center;
}

#sidebar .sidebar-header .row .profile-info {
    text-align: right;
    padding: 0%;
    margin: 0px;
    align-self: center;
}

#sidebar .sidebar-header p {
    color: #eee;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#sidebar .sidebar-header img {
    width: 90px;
    height: 90px;
    text-align: right;
    border-radius: 50%;
    padding: 0.20rem;
    background-color: #fff;
    border: 2px solid var(--Cuboh-color-blue1);
    max-width: 100%;
}

#sidebar ul.components {
    padding: 20px 0px;
    border-bottom: 1px solid var(--Cuboh-color-blue1);
    border-top: 1px solid var(--Cuboh-color-blue1);
    
    
}

#sidebar ul p {
    color: #eee;
    padding: 5px;
}

#sidebar ul li a, #sidebar ul li span {
    padding: 10px;
    font-weight: 500;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover, #sidebar ul li span:hover {
    color: var(--Cuboh-color-blue3);
    background: #eee;
}

#sidebar ul li.active>a, a[aria-expanded="true"], #sidebar ul li.active>span, span[aria-expanded="true"] {
    color: #eee;
    background: var(--Cuboh-color-blue3);
}

#sidebar ul li small {
    color: #fff;
    font-size: 10px;
    font-weight: 500;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: var(--Cuboh-color-blue3);
}