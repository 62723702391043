* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

#Congratulation {
    /* fallback for old browsers */
    background: var(--Cuboh-color-purple1);
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    width: 100%;
    height: 100vh;
}

body {
    padding: 0;
    margin: 0;
}

#Congratulation #crudAvatarPefil {
    width: 120px;
    height: 110px;
    text-align: center;
    border-radius: 50%;
    padding: 0.15rem;
    background-color: #eee;
    border: 1.5px solid var(--Cuboh-color-blue3);
    max-width: 100%;
}

#Congratulation textarea {
    font-size: 0.75rem;
}

#Congratulation div.card-body {
    border-right: 1.5px solid var(--Cuboh-color-blue3);
}

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 650px;
    width: 100%;
    text-align: center;
}

.notfound .notfound-404 {
    height: 280px;
    position: relative;
    z-index: -1;
}

.notfound .notfound-404 h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 150px;
    margin: 0px;
    font-weight: 900;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    /*background: url('../../../src/assets/imgs/congratulationBg2.jpg') no-repeat; */
    color: white;
    animation: colorchange 10s;
    -webkit-animation: colorchange 10s;
    /*
    -webkit-text-fill-color: transparent;
    */
    background-size: cover;
    -webkit-background-clip: text;
    background-position: center;
}

@media only screen and (max-width: 767px) {

    #Congratulation div.card-body {
        border: none;
    }

    .notfound .notfound-404 {
        height: 142px;
    }
    .notfound .notfound-404 h2 {
        font-size: 52px;
    }
}