
body {
  margin: 0;
  /* font-family: poppins-Light, -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: 'Poppins', Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: poppins-Light, source-code-pro, Menlo, Monaco, Consolas, "Roboto", "Courier New", monospace; */
  font-family: 'Poppins', Arial, serif;
}