.login-sec {
    padding: 50px 30px;
    position: relative;
}

.login-sec .copy-text {
    position: absolute;
    width: 80%;
    bottom: 20px;
    font-size: 13px;
    text-align: center;
}

.login-sec .copy-text i {
    color: var(--Cuboh-color-blue3);
}

.login-sec .copy-text a {
    color: var(--Cuboh-color-purple1);
}

.login-sec h2 {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 30px;
    color: var(--Cuboh-color-purple1);
}

.login-sec h2:after {
    content: " ";
    width: 100px;
    height: 5px;
    background: var(--Cuboh-color-blue3);
    display: block;
    margin-top: 20px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto
}

.btn-outline-info:hover {
    background: var(--Cuboh-color-purple1);
}

.btn-outline-info {
    color: var(--Cuboh-color-purple1);
    border-color: var(--Cuboh-color-purple1);
    --info: var(--Cuboh-color-purple1);
}

.btn-login {
    background: var(--Cuboh-color-purple1);
    color: #fff;
    font-weight: 600;
}

.btn-login:hover {
    background: var(--Cuboh-color-blue3);
    color: #fff;
    font-weight: 600;
}