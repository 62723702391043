div#testsMode button.btn-rounded-left {
    border-radius: 50rem 0rem 0rem 50rem;
}

div#testsMode button.btn-rounded-right {
    border-radius: 0rem 50rem 50rem 0rem;
}

div#testsMode button {
    font-weight: 700;
    font-size: 0.9rem;
}

div#testsMode button.buttonActive {
    background-color: var(--Cuboh-color-blue3);
    color: #fff;
}

div#testsMode button.buttonInative {
    color: var(--Cuboh-color-blue3);
    border-color: var(--Cuboh-color-blue3);
}

div#testsMode button:disabled {
    background-color: #eee;
    color: gray;
    border: none;
}

div#testsMode .separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 0.95rem;
    color: var(--Cuboh-color-blue3);
}

div#testsMode .separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--Cuboh-color-blue3);
}

div#testsMode .separator::before {
    margin-right: 3%;
}

div#testsMode .separator::after {
    margin-left: 3%;
}