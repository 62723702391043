.login-sec {
    padding: 20px 3 0px;
    position: relative;
    text-align: center;
}

.login-sec .copy-text {
    position: absolute;
    width: 80%;
    bottom: 20px;
    font-size: 13px;
    text-align: center;
}

.login-sec .copy-text i {
    color: var(--Cuboh-color-purple1);
}

.login-sec .copy-text a {
    color: var(--Cuboh-color-blue3);
}

.login-sec h2 {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    letter-spacing: 1px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: var(--Cuboh-color-blue3);
}

.login-sec h2:after {
    content: " ";
    width: 140px;
    height: 6px;
    background: var(--Cuboh-color-blue3);
    display: block;
    margin-top: 5px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto
}

.login-sec label {
    font-weight: bold;
    color: var(--Cuboh-color-purple1);
}

.btn-outline-info:hover {
    background: var(--Cuboh-color-purple1);
}

.btn-outline-info {
    color: var(--Cuboh-color-purple1);
    border-color: var(--Cuboh-color-purple1);
    --info: var(--Cuboh-color-purple1);
}

.btn-login {
    background: var(--Cuboh-color-purple1);
    color: #fff;
    font-weight: 600;
}

input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: black;
    font-size: 0.9rem;
}

input::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--Cuboh-color-purple1);
    font-size: 0.9rem;
}

input:-ms-input-placeholder {
    /* IE 10+ */
    color: var(--Cuboh-color-purple1);
    font-size: 0.9rem;
}

input:-moz-placeholder {
    /* Firefox 18- */
    color: var(--Cuboh-color-purple1);
    font-size: 0.9rem;
}

.ButtonsOfLogin{
    font-size: 0.8rem;
}