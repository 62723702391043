#SetHeader #crudLogoHeader {
    max-width: 200px;
    max-height: 150px;
    min-height: 50px;
    text-align: center;
    padding: 0.15rem;
    margin: 8px 5px 8px 5px;
    background-color: #fff;
    /*border: 1.5px solid var(--Cuboh-color-blue3);*/
}

#SetHeader .examplaLogoHeader {
    max-width: 400px;
    max-height: 330px;
}

#SetHeader .inputFileImageUrl {
    position: relative;
    width: 100%;
    background-color: #fff;
}