main.content #dashboard .card .bord-left-add {
    border-left: .40rem solid var(--Cuboh-color-blue3);
    border-radius: 10px;
}

main.content #dashboard .card .card-body {
    padding: 0.5rem;
    height: 100%;
    text-align: left;
}

main.content #dashboard .card .card-body .placa {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    border: .1rem solid #eee;
    border-left: .40rem solid var(--Cuboh-color-blue3);
}

main.content #dashboard .card .card-body .placa .text-value {
    font-size: 50px;
    color: white;
    -webkit-text-stroke-width: .1rem;
    -webkit-text-stroke-color: var(--Cuboh-color-blue3);
}

main.content #dashboard .card .card-body .placa .text-value-title {
    margin: 5px 10px;
    color: var(--Cuboh-color-blue3);
    font-weight: bolder;
}

main.content #dashboard .card .card-header {
    display: flex;
    align-items: left;
}

main.content #dashboard .card .card-header .colapseIcon {
    font-size: 2em;
}

main.content #dashboard .card .text-title-canva {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

main.content #dashboard .card .text-title-card {
    color: var(--Cuboh-color-purple1);
    font-size: 0.9rem;
    display: flex;
    width: 100%;
    height: 100%;
}

main.content #dashboard .card .text-simbol-card {
    font-size: 3em;
    color: #dddfeb;
}

main.content #dashboard .card .card-body .text-dashboard-empty {
    font-size: 1rem;
    color: var(--Cuboh-color-blue3);
}