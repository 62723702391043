/*@import url("//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css");*/

.login-block {
    /* fallback for old browsers */
    background: var(--Cuboh-color-purple1);
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue3));
    float: left;
    width: 100%;
    height: 100vh;
    padding: 50px 0;
}

.banner-sec {
    background: var(--Cuboh-color-blue3);
    background-size: cover;
    border-radius: 0 10px 10px 0;
    padding: 0;
}

.containerCarroussel {
    display: absolute;
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
    max-width: 90%;
    max-height: 100%;
    box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}

#login-block img.img-logo {
    width: 250px;
    height: 75px;
}

.textCreatedCuboh {
    font-size: 0.7rem;
}

@media(max-width: 575px) {
    .containerCarroussel {
        max-height: 350%;
    }
    .login-block {
        height: 100vh;
    }
}