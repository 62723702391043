.buttonRemoveTestsVsGroups {
    color: #6c757d;
}

.buttonRemoveTestsVsGroups:hover {
    color: white;
    text-align: center;
    overflow: visible;
}

div#modalAddTestsVsGroups span.input-group-text {
    background: var(--Cuboh-color-purple1);
    color: #fff;
    font-weight: 700;
    min-width: 70px;
    justify-content: center;
    max-width: 120px;
    overflow: hidden;
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-header {
    background-color: var(--Cuboh-color-purple1);
    color: #fff;
    font-weight: 700;
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-header div {
    padding: 2px 10px 2px 10px;
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-body {
    display: flexbox;
    border: var(--Cuboh-color-purple1) 1px solid;
    padding: 0px;
    margin: 0px;
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-body ul {
    display: flexbox;
    max-height: 200px;
    overflow-y: scroll;
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-body ul li {
    display: flexbox;
    font-weight: normal;
    padding: 5px;
    padding-left: 10px;
    color: var(--Cuboh-color-purple1)
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-body ul li:hover {
    background-color: var(--Cuboh-color-blue3);
    filter: alpha(opacity=90);
    color: #fff;
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-body ul li i {
    padding-right: 10px;
    font-size: 25px;
}

div#modalAddTestsVsGroups div#modalCardTestsVsGroups div.card-body ul li i:hover {
    cursor: pointer;
}

div.table-responsive-sm tbody td.text-name-long {
    max-width: 20vh;
}