table thead {
    /* background: var(--Cuboh-color-blue3); */
    background-image: linear-gradient(150deg, var(--Cuboh-color-blue3), var(--Cuboh-color-blue3), var(--Cuboh-color-purple1) 65%);
    color: #eee;
    font-weight: bold;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

table tfoot {
    /* background: var(--Cuboh-color-blue3); */
    background-image: linear-gradient(150deg, var(--Cuboh-color-blue3), var(--Cuboh-color-blue3), var(--Cuboh-color-purple1));
    color: #eee;
    font-weight: bold;
    background-repeat: no-repeat;
    background-attachment: fixed;
}