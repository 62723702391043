:root {
    height: 100%;
    width: 100%;
    background: -webkit-linear-gradient(to right, #E2E2E2, #FFF);
    background: linear-gradient(to right, #E2E2E2, #FFF);
    /* Paletas de cores*/
    --bg-nav: #E2E2E2;
    --bg-content: #FFF;
    --Cuboh-color-purple1: #4516d8;
    --Cuboh-color-blue1: #4dffff;
    --Cuboh-color-blue2: #26dbff;
    --Cuboh-color-blue3: #00a2f2;
    --font-white: #eee;
    /* /* //Paletas de cores
    --bg-nav: #E2E2E2;
    --bg-content: #FFF;
    --Cuboh-color-blue3:var(--Cuboh-color-blue1);
    --Cuboh-color-purple1: var(--Cuboh-color-purple1);
    --font-white: #eee; */
    /*
    Roxo: 4516d8 
	Azul 1 : 4dffff
	Azul 2 : 26dbff
    Azul 3 : 00a2f2
    background:var(--Cuboh-color-blue1);
    background: -webkit-linear-gradient(to right, var(--Cuboh-color-purple1),var(--Cuboh-color-blue1));
    background: linear-gradient(to right, var(--Cuboh-color-purple1),var(--Cuboh-color-blue1)); 
    cinza : #d3cce3 #e9e4f0
    */
    /* Dimnensões */
    --logo-height: 100px;
    --header-height: 56px;
    --menu-top-height: 60px;
    --aside-width: 225px;
    --footer-height: 40px;
    --shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1), 0 2px 49px 0 rgba(0, 0, 0, 0.06);
}

* {
    box-sizing: border-box;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: 'Poppins', Arial, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

/* System Grid */

.app {
    margin: 0px;
    display: grid;
    grid-template-columns: var(--aside-width) 1fr;
    grid-auto-rows: var(--header-height) 1fr;
    grid-template-areas: /*Coluna 01       Coluna 02*/
    /*L1*/
    "logo            header"
    /*L2*/
    "menu           content"
    /*L3*/
    "footer           content";
    min-height: 100vh;
    height: 100%;
    background-color: #f5f5f5;
}

aside.logo {
    grid-area: logo;
}

header.header {
    grid-area: header;
}

.text-CubohColor1 {
    color: var(--Cuboh-color-blue3);
}

.text-CubohColor2 {
    color: var(--Cuboh-color-purple1)
}

.bg-Cuboh-color-purple1 {
    background: var(--Cuboh-color-purple1);
    color: #fff;
    font-weight: 600;
}

.bg-Cuboh-color-blue1 {
    background: var(--Cuboh-color-blue1);
    color: #fff;
    font-weight: 600;
}

.bg-Cuboh-color-blue2 {
    background: var(--Cuboh-color-blue2);
    color: #fff;
    font-weight: 600;
}

.bg-Cuboh-color-blue3 {
    background: var(--Cuboh-color-blue3);
    color: #fff;
    font-weight: 600;
}

.bg-Cuboh-degrade1 {
    background-image: linear-gradient(150deg, var(--Cuboh-color-blue3), var(--Cuboh-color-blue3), var(--Cuboh-color-purple1));
    color: #fff;
    font-weight: 600;
}

/*
aside .menu {
    grid-area: menu;
}
*/

div.wrapper {
    grid-area: menu;
}

main.content {
    grid-area: content;
}

footer.footer {
    grid-area: footer;
}

.btn.disabled, .btn:disabled {
    cursor: no-drop;
    background-color: slategray;
}

/*Na paginação o numero 1 estava cubrindo o nav*/

.page-item.active .page-link {
    z-index: 1;
}

@media(max-width: 768px) {
    .app {
        grid-template-rows: var(--header-height) 3px 1fr var(--footer-height);
        grid-template-columns: var(--aside-width) 1fr;
        grid-template-areas: "logo header" "menu menu" "content content" "footer footer";
    }
    div.wrapper #sidebar {
        margin-left: -250px;
        transition-duration: 0.7s;
    }
    div.wrapper #sidebar.active {
        margin-left: 0;
        transition-duration: 0.7s;
        float: left;
        position: absolute;
        z-index: 2;
    }
}

@media(max-width: 575px) {
    .app {
        grid-template-rows: var(--header-height) 3px 1fr var(--footer-height);
        grid-template-columns: 110px 1fr;
        grid-template-areas: "logo header" "menu menu" "menu content" "footer footer"
    }
}