/* Esconde o input */
input[type='file'] , #clearImg{
    display: none;
}

main.content #crudAvatarPefil {
    width: 200px;
    height: 190px;
    text-align: center;
    border-radius: 50%;
    padding: 0.15rem;
    background-color: #eee;
    border: 4px solid var(--Cuboh-color-blue3);
    max-width: 100%;
}

