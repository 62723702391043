section {
    padding: 20px 0;
}

section .section-title {
    text-align: center;
    color: var(--Cuboh-color-purple1);
    text-transform: uppercase;
}

#what-we-do {
    /* fallback for old browsers */
    background: var(--Cuboh-color-purple1);
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue2));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, var(--Cuboh-color-purple1), var(--Cuboh-color-blue2));
    width: 100%;
    height: 100vh;
}

.container {
    padding: 0px;
    display: absolute;
    background: #fff;
    /*overflow-x: hidden;*/
    border-radius: 10px;
    box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}

#what-we-do .card {
    padding: 1rem!important;
    border: none;
    margin-bottom: 1rem;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}

#what-we-do .card:hover {
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

#what-we-do .card .card-block {
    padding-left: 50px;
    position: relative;
}

#what-we-do .card .card-block a {
    color: var(--Cuboh-color-purple1) !important;
    font-weight: 700;
    text-decoration: none;
}

#what-we-do .card .card-block a i {
    display: none;
}

#what-we-do .card:hover .card-block a i {
    display: inline-block;
    font-weight: 700;
}

#what-we-do .card .card-block:before {
    font-family: FontAwesome;
    position: absolute;
    font-size: 39px;
    color: var(--Cuboh-color-purple1);
    font-weight: bold;
    left: 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
}

#what-we-do .card .block-1:before {
    content: "1º";
}

#what-we-do .card .block-2:before {
    /*content: "\f0eb";*/
    content: "2º";
}

#what-we-do .card .block-3:before {
    content: "3º";
}

#what-we-do .card .block-4:before {
    content: "4º";
}

#what-we-do .card .block-5:before {
    content: "5º";
}

#what-we-do .card .block-6:before {
    content: "6º";
}

#what-we-do .card:hover .card-block:before {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}

#what-we-do #crudAvatarPefil {
    width: 180px;
    height: 170px;
    text-align: center;
    border-radius: 50%;
    padding: 0.15rem;
    background-color: #eee;
    border: 4px solidvar(--Cuboh-color-blue1);
    max-width: 100%;
}

#what-we-do img.iconStep {
    width: 180px;
    height: 170px;
}

#what-we-do #stepsToStart {
    min-height: 300px;
}

#what-we-do ol.stepsCount {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

#what-we-do ol.stepsCount li {
    content: '1';
    color: #000;
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 20px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-radius: 80px;
    opacity: .5;
    transition: opacity .6s ease;
}

#what-we-do ol.stepsCount li.active {
    background-color: var(--Cuboh-color-purple1);
}