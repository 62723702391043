.divLoading {
    position: fixed;
    /* Sit on top of the page content */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
}

.divLoading-none {
    background-color: rgba(0, 0, 0, 0);
}

.imagecuboh {
    width: 200px;
    height: 200px;
}

.iconLoading {
    display: block!important;
    flex-direction: column;
    text-align: center;
    margin-top: 20%;
}

.color-header {
    color: var(--Cuboh-color-blue3);
    text-align: center;
}

.text-loading {
    text-transform: uppercase;
    text-align: center;
    margin-top: 1%;
    color: white;
    font-size: 30px;
}