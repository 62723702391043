#SetCover #crudLogoHeader {
    max-width: 120px;
    max-height: 120px;
    
    text-align: center;
    padding: 0.15rem;
    margin: 13px 5px 8px 5px;
    background-color: #fff;
}

#SetCover .examplaLogoHeader {
    max-width: 400px;
    max-height: 330px;
}

#SetCover .inputFileImageUrl {
    position: relative;
    width: 100%;
    background-color: #fff;
}