
.d-inline{
    display: inline-grid!important;
}

p{
    font-size: 14px;
}

.card {
    font-weight: bold;
}

.displayReport{
    position: relative;
    display: flex;
}


.cardCanvas{
    position: relative;
    min-width: 0;
    flex-direction: column;
    flex-flow: column;
    background-color: rgba(255, 255, 255, 0.4);
    background-clip: border-box;
    border-style: solid;
    border-width: 1.5px;
    border-color: var(--Cuboh-color-blue3);
    border-radius: .50rem;
}

.titleCanvas{
    text-align: center;
    font-weight: 600;
    color: var(--Cuboh-color-purple1);
    font-size: 30px;
}

.titleCanvas .subtitleCanvas{
    text-align: justify;
    color: var(--Cuboh-color-blue3);
    font-size : 17px;
    font-weight: lighter;
}