div#Home div#cardHome body {
    margin-top: 20px;
    background: #FAFAFA;
}

div#Home div#cardHome .order-card {
    color: #fff;
}

div#Home div#cardHome .break-colums {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: auto;
}

div#Home div#cardHome .bg-c-white {
    background: linear-gradient(50deg, var(--Cuboh-color-purple1), #fff);
}

div#Home div#cardHome .bg-c-blue {
    background: linear-gradient(150deg, var(--Cuboh-color-blue3), var(--Cuboh-color-blue3), var(--Cuboh-color-purple1));
}

div#Home div#cardHome .bg-c-green {
    background: linear-gradient(50deg, #2ed8b6, var(--Cuboh-color-blue3));
}

div#Home div#cardHome .bg-c-yellow {
    background: linear-gradient(50deg, #FFB64D, var(--Cuboh-color-blue3));
}

div#Home div#cardHome .bg-c-pink {
    background: linear-gradient(50deg, #FF5370, var(--Cuboh-color-blue3));
}

div#Home div#cardHome .card {
    border-radius: 8px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.30);
    box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.30);
    border: none;
    margin-bottom: 13px;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

div#Home div#cardHome .card .loadingIcon {
    opacity: 0.4;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

div#Home div#cardHome .card-item {
    background-color: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 4px 0.3px rgba(4, 26, 55, 0.40);
    box-shadow: 0px 4px 4px 0.3px rgba(4, 26, 55, 0.40);
    width: 90px;
    height: 70px;
    border: none;
    word-wrap: initial;
    color: var(--Cuboh-color-blue3);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

div#Home div#cardHome .card-item:hover {
    cursor: pointer;
    background-color: var(--Cuboh-color-purple1);
    color: #fff;
    -webkit-box-shadow: 0px 4px 4px 0.3px rgba(255, 255, 255, 0.3);
    box-shadow: 0px 4px 4px 0.3px rgba(255, 255, 255, 0.3);
    transition: color 0.35s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

div#Home div#cardHome .menu-fast .menu-fast-title {
    font-size: 25px;
    color: #fff;
}

div#Home div#cardHome .menu-fast .menu-fast-icon {
    font-size: 50px;
    font-weight: 800;
    color: #fff;
}

div#Home div#cardHome .card-item .card-title {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.8;
}

div#Home div#cardHome .card-item i {
    font-size: 25px;
    opacity: 0.9;
}

div#Home div#cardHome .card .card-block {
    padding: 20px;
}

div#Home div#cardHome .card .card-block .card-title {
    font-size: 22px;
    font-weight: 600;
}

div#Home div#cardHome .card .card-block .card-value {
    font-weight: bold;
    opacity: 0.9;
    font-size: 30px;
}

div#Home div#cardHome .card .card-block .card-value .card-value-percent {
    font-size: 15px;
    font-weight: bold;
    margin: 0%;
    padding: 0%;
}

div#Home div#cardHome .card .card-block .card-value-legend {
    font-size: 0.9rem;
    font-weight: 600;
}

div#Home div#cardHome .card .card-block .list-tests {
    background: none;
}

div#Home div#cardHome .card .card-block .list-tests li {
    background-color: inherit;
    color: #fff;
    border-bottom: 1px solid #fff;
    font-size: 1.1em;
}

div#Home div#cardHome .order-card span.legend-bar {
    color: #fff;
    border-right: #eee 1px solid;
    min-width: 73px;
    line-height: 1;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

div#Home div#cardHome .card .order-card i {
    font-size: 37px;
    opacity: 0.5;
}

div#Home div#cardHome .f-left {
    float: left;
}

div#Home div#cardHome .f-right {
    float: right;
}

@media (max-width: 575px) {
    div#Home div#cardHome .break-colums {
        grid-template-columns: 33% 33% 33%;
    }
    div#Home div#cardHome .menu-fast .menu-fast-icon {
        position: absolute;
        font-size: 30px;
        top: 0;
        right: 0;
        padding-right: 20px;
        opacity: 0.6;
    }
}