aside.logo {
    background: var(--Cuboh-color-purple1);
    display: flex;
    overflow: hidden;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
}

aside.logo img#cubohName {
    padding: 10px;
    width: 100%;
    height: 100%;
    -webkit-filter: drop-shadow( 2px 2px 10px #eee);
    filter: drop-shadow( 3px 3px 35px #eee);
}

aside.logo img#cubohFace {
    width: 50px;
    height: 50px;
    -webkit-filter: drop-shadow( 100px 100px 100px #fff);
    filter: drop-shadow( 1px 1px 5px #fff);
}