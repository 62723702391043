div#TestsVarious table tr {
    font-size: 0.75rem;
    align-items: center;
}

div#TestsVarious table tr td {
    vertical-align: middle;
}

div#TestsVarious div.table-responsive{
    display: flexbox;
    max-height: 150px;
    overflow-y: scroll;
}

div#TestsVarious button.btn-insertTests {
    color: var(--Cuboh-color-blue3);
    border: 1px solid var(--Cuboh-color-blue3);
}

div#TestsVarious button.btn-insertTests:hover {
    background: var(--Cuboh-color-blue3);
    color: #fff;
}

div#TestsVarious tbody tr td i{
    color: gray;
    font-size: 1.1rem;

}
div#TestsVarious tbody tr td i:hover{
    color: var(--Cuboh-color-blue3);
    cursor: pointer;
}
